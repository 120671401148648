window.bootstrap = require("bootstrap")


// Function to set the "CookieConsent" cookie with a fixed expiration period of 30 days
export function setCookie(value) {
  var expires = "; expires=" + new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toUTCString();
  document.cookie = "CookieConsent" + "=" + encodeURIComponent(value) + expires + "; path=/";
}

// Function to retrieve preferences from form and set them as cookies
export function savePreferencesFromForm() {
  // Retrieve preferences from the form
  var necessaryCheckbox = document.getElementById('cookie-necessary');
  var preferencesCheckbox = document.getElementById('cookie-preferences');
  var statisticsCheckbox = document.getElementById('cookie-analytics');
  var marketingCheckbox = document.getElementById('cookie-marketing');

  // Construct preferences object
  var preferences = {
    necessary: necessaryCheckbox.checked,
    preferences: preferencesCheckbox.checked,
    statistics: statisticsCheckbox.checked,
    marketing: marketingCheckbox.checked
  };

  // Convert preferences to JSON string
  var preferencesJSON = JSON.stringify(preferences);

  // Hide the modal
  hideModal();

  // Set "CookieConsent" cookie with JSON string
  setCookie(preferencesJSON);

}

// Function to set preferences to deny (necessary true, others false)
export function denyPreferences() {
  var necessaryCheckbox = document.getElementById('cookie-necessary');
  necessaryCheckbox.checked = true;

  var preferencesCheckbox = document.getElementById('cookie-preferences');
  preferencesCheckbox.checked = false;

  var statisticsCheckbox = document.getElementById('cookie-analytics');
  statisticsCheckbox.checked = false;

  var marketingCheckbox = document.getElementById('cookie-marketing');
  marketingCheckbox.checked = false;
}

// Function to set preferences to accept (all true)
export function acceptPreferences() {
  var necessaryCheckbox = document.getElementById('cookie-necessary');
  necessaryCheckbox.checked = true;

  var preferencesCheckbox = document.getElementById('cookie-preferences');
  preferencesCheckbox.checked = true;

  var statisticsCheckbox = document.getElementById('cookie-analytics');
  statisticsCheckbox.checked = true;

  var marketingCheckbox = document.getElementById('cookie-marketing');
  marketingCheckbox.checked = true;
}

// Function to hide the modal
export function hideModal() {
  var cookieModal = bootstrap.Modal.getOrCreateInstance('#cookie-modal');
  cookieModal.hide();
}

// Function to check if the cookie exists
export function checkCookie() {
  var cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)CookieConsent\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  if (cookieValue === "") {
    var cookieModal = bootstrap.Modal.getOrCreateInstance('#cookie-modal');
    cookieModal.show();
  }
}
