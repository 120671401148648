// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { checkCookie, savePreferencesFromForm, acceptPreferences, denyPreferences } from './cookie-consent.js';
// import CookiesEuBanner from 'cookies-eu-banner'

window.bootstrap = require("bootstrap")

require('@fortawesome/fontawesome-free/js/all')

window.WOW = require('wowjs').WOW;

Rails.start()
ActiveStorage.start()

import "controllers"

$(document).on("turbo:load", () => {
  console.log("Turbo Loaded!")

  loadWow()

  window.onscroll = function () {
    var header_navbar = document.querySelector(".navbar-area");
    var sticky = header_navbar.offsetTop;

    if (window.pageYOffset > sticky) {
      header_navbar.classList.add("sticky");
    } else {
      header_navbar.classList.remove("sticky");
    }
  };

  let navbarToggler = document.querySelector(".navbar-toggler");
  navbarToggler.addEventListener('click', function () {
    navbarToggler.classList.toggle("active");
  })

  window.onscroll = function () {
    var header_navbar = document.querySelector(".navbar-area");
    var sticky = header_navbar.offsetTop;
    if (window.pageYOffset > sticky) {
      header_navbar.classList.add("sticky");
    } else {
      header_navbar.classList.remove("sticky");
    }
    var backToTo = document.querySelector(".scroll-top");
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      backToTo.style.display = "block";
    } else {
      backToTo.style.display = "none";
    }
  };

  $("a[href^='#']").on('click', function (e) {
    e.preventDefault();

    if(e.currentTarget.classList.contains("scroll-top")) {
      $('html, body').animate({
        scrollTop: document.documentElement
      }, 300, function () {
      });
    };
  });

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  document.getElementById('denyButton')?.addEventListener('click', function () {
    denyPreferences();
    savePreferencesFromForm();
  });

  document.getElementById('acceptButton')?.addEventListener('click', function () {
    acceptPreferences();
    savePreferencesFromForm();
  });

  document.getElementById('saveButton')?.addEventListener('click', function () {
    savePreferencesFromForm();
  });

  checkCookie();

});

function loadWow() {
  new WOW({ live: false, mobile: false }).init();
  $('.wow').attr({ 'data-wow-duration': '2s', 'data-wow-delay': '.1s', 'data-wow-offset': '10' });
  $('.wow.pulse').attr({ 'data-wow-duration': '0.5s' });
};
